/* You can add global styles to this file, and also import other style files */
@import './nexato-theme.scss';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import "~jsoneditor/dist/jsoneditor.min.css";

.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
  transform: none !important;
}

body{
  margin:0;
}

a {
  color:black;
}

.dnd-drag-start {
  -moz-transform:scale(0.8);
  -webkit-transform:scale(0.8);
  transform:scale(0.8);
  opacity:0.7;
  border: 1px dashed #000;
}

.dnd-drag-enter {
  opacity:0.7;
  border: 1px dashed #000;
}

.dnd-drag-over {
  border: 1px dashed #000;
}

.dnd-sortable-drag {
-moz-transform:scale(0.9);
-webkit-transform:scale(0.9);
transform:scale(0.9);
opacity:0.7;
border: 1px dashed #000;
}



.TESTPENISLINKS, .TESTPENISLINKS.tr.mat-row {
  height: 32px !important;
  overflow: hidden !important;
  background: rgba(231, 231, 231, 0.671) !important;
  opacity: 0,4 !important;
  color: grey !important;
  display: block !important;
}
.TESTPENISLINKS * , .TESTPENISLINKS.tr.mat-row *{
  height: 32px !important;
  overflow: hidden !important;
  color: rgb(192, 192, 192) !important;
  opacity: 0,4 !important;
  color: grey !important;
  display: block !important;
}

.task.TESTPENISLINKS{
  height: 52px !important;
  overflow: hidden !important;
  background: rgba(231, 231, 231, 0.671) !important;
  opacity: 0,4 !important;
  //color: grey !important;
  //display: block !important;
  border:0 !important;
}

.task.TESTPENISLINKS *{
  height: 52px !important;
  overflow: hidden !important;
  //color: rgb(192, 192, 192) !important;
  opacity: 0,4 !important;
  //color: grey !important;
  //display: block !important;
  border:0 !important;
}

.zwo{
  display:none  !important;
}


.sortable-drag * {
  padding-left: 20px;
}

.app-tours-planner-page{
  display: block
}



.protocolNotes, .protocolNote, .note{
  text-align: left;
}

.protocolNotes h2, .note h2{
  font-size: 16px;
  margin-bottom: 0;
  line-height: 20px;
}

.protocolNote h3{
  font-size: 14px;
  font-weight:bold;
  margin-bottom: 0;
  line-height: 18px;
}

.protocolNote .name{
  font-size: 12px;
  margin-bottom: 0;
}

.protocolNote .note{
  font-size: 12px;
  font-style: italic;
}

.protocolNoteTooltip{
  background: rgba(97,97,97,.9) !important;
}